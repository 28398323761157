<div class="suggested-resources position-relative">
    <div class="background-gradient">
        <div class="circle-top-left"></div>
        <div class="circle-lower-right"></div>
    </div>

    <div class="container-fluid">
        <div class="container-xxxl pt-5">
            <app-bar-header [textHeader]="textHeader" theme="white"></app-bar-header>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-xxxl">
            <div class="row summary mb-3 pb-3 mb-lg-5 pb-lg-0">
                <div class="col col-lg-6 p-0 fs-md-18">
                    <p class="mb-0 ps-3 text-white" [innerHtml]="summary"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid bottom overflow-hidden position-relative" *ngIf="tabs?.length">
        <app-multitab [tabs]="tabs" [hideTabs]="true"></app-multitab>
    </div>
</div>
