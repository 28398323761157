import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleService } from 'src/app/services/article.service';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { DISEASE_SECTIONS } from 'shared/constants';

interface ArticleWithLink extends Article {
    link: string;
}

@Component({
    selector: 'app-disease-footer',
    templateUrl: './disease-footer.component.html',
    styleUrls: ['./disease-footer.component.scss'],
})
export class DiseaseFooterComponent implements OnInit {
    breadcrumbArticles: ArticleWithLink[] = [];
    activeIndex = -1;

    constructor(private articleService: ArticleService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.populateBreadCrumbArticles();
        this.setCurrentIndex();
    }

    private populateBreadCrumbArticles() {
        const path = this.route.snapshot.routeConfig.path;
        const prefix = path.length ? '../' : '';

        /* About */
        const aboutArticle = this.articleService.getOne(Page.all, Section.breadcrumb, SubSection.about);
        this.breadcrumbArticles.push({ ...aboutArticle, link: prefix + DISEASE_SECTIONS.about.link });

        /* Diagnosis */
        const diagnosisArticle = this.articleService.getOne(Page.all, Section.breadcrumb, SubSection.diagnosis);
        this.breadcrumbArticles.push({ ...diagnosisArticle, link: prefix + DISEASE_SECTIONS.diagnosis.link });

        /* Living With */
        const livingWithArticle = this.articleService.getOne(Page.all, Section.breadcrumb, SubSection.livingWith);
        this.breadcrumbArticles.push({ ...livingWithArticle, link: prefix + DISEASE_SECTIONS.living.link });
    }

    private setCurrentIndex() {
        const path = this.route.snapshot.routeConfig.path;

        switch (path) {
            case DISEASE_SECTIONS.about.link:
                this.activeIndex = 0;
                return;
            case DISEASE_SECTIONS.diagnosis.link:
                this.activeIndex = 1;
                return;
            case DISEASE_SECTIONS.living.link:
                this.activeIndex = 2;
                return;
        }
    }
}
