import { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { DiseaseService } from 'src/app/services/disease.service';
import { EXTERNAL_POPUP_TEXT_EN, LIVING_SECTIONS } from 'shared/constants';
import { Disease } from 'shared/types';

@Component({
    selector: 'app-disease-living-with',
    templateUrl: './disease-living-with.component.html',
    styleUrls: ['./disease-living-with.component.scss'],
})
export class DiseaseLivingWithComponent implements AfterViewInit {
    public sections = LIVING_SECTIONS;
    public disease: Disease;

    constructor(diseaseService: DiseaseService) {
        this.disease = diseaseService.disease;
    }

    ngAfterViewInit() {
        const links = Object.values(document.getElementsByTagName('a')).filter(link => !link.href.includes('.gov') && !link.baseURI.includes(link.hostname));

        links.forEach(linkEl => {
            const href = linkEl.href;
            linkEl.addEventListener('click', (ev: MouseEvent) => this.onOutsideRedirect(ev, href));
        });
    }

    onOutsideRedirect(ev: MouseEvent, link: string) {
        ev.preventDefault();
        ev.stopPropagation();
        if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
            const tab = window.open(link, '_blank');
            tab.opener = null;
        }
    }
}
