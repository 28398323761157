import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { ACCOUNT_RECORD_TYPES } from 'shared/constants';
import { PATHS } from 'shared/paths';
import { Account, Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { DiseaseService } from 'src/app/services/disease.service';
import { AccountService } from 'src/app/services/account.service';
import { LocalOrganization } from 'src/app/components/common/organizations/organizations-interfaces';
import { availablePatientAdvocacyGroups } from 'src/app/components/common/organizations/patient-advocacy-groups';

@Component({
    selector: 'living-with-intro',
    templateUrl: './living-with-intro.component.html',
    styleUrls: ['./living-with-intro.component.scss'],
})
export class LivingWithIntroComponent {
    public imgPath = PATHS.articleImages;

    public parentKA: Article;

    // public linkKA: Article;

    // public nullKA: Article;

    // public orgsWithExpertDirectory: LocalOrganization[] = [];

    constructor(private articleService: ArticleService, private diseaseService: DiseaseService, private accountService: AccountService) {
        this.parentKA = this.articleService.getOne(Page.livingWith, Section.parent, SubSection.intro, undefined, undefined, false);

        // this.linkKA = this.articleService.getOne(Page.diagnosis, Section.findingExpert, Subsection.moreInfo, undefined, undefined, false);

        // this.nullKA = this.articleService.getOne(Page.diagnosis, Section.findingExpert, Subsection.moreInfo, undefined, undefined, true);

        // // add replacement for disease name
        // this.linkKA.bodyText = this.linkKA.bodyText.replace('${DiseaseName}', this.diseaseService.disease.name);

        // // Accounts logic
        // const patientAdvocacyGroups: Account[] = this.accountService.getAccounts().filter((acct: Account) => acct.recordType === ACCOUNT_RECORD_TYPES.patientAdvocacyGroup);

        // const pagsForDiseaseAndTag = availablePatientAdvocacyGroups(patientAdvocacyGroups, this.diseaseService.disease, true, true, false);
        // this.orgsWithExpertDirectory = pagsForDiseaseAndTag.filter((org: LocalOrganization) => org.expertDirectoryUrl);
    }
}
