/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SalesforceArticleDto } from '../../../data-import/src/dto/article/salesforce-article.dto';
import { map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { PATHS } from '../../../shared/paths';
import { Article, Image } from '../../../shared/types';
import { ErrorLogService } from './error-log.service';
import { Language } from '../../../shared/salesforce-types';
import { LANGUAGE } from '../../../shared/injection-tokens';
import { Page, Section, SubSubsection, SubSection } from 'shared/location-enums';
import { SalesforceContentVersionListDto } from 'data-import/src/dto/article/salesforce-content-version-list.dto';

export interface ArticleParams {
    tab?: Page;
    section?: Section;
    subSection?: SubSection;
    subSubsection?: SubSubsection;
    order?: number;
    allPages?: boolean;
    nullVersion?: boolean;
    language?: Language;
}

@Injectable({
    providedIn: 'root',
})
export class ArticleService {
    private articles = [] as Article[];

    constructor(private errorLogService: ErrorLogService, private http: HttpClient, @Inject(LANGUAGE) private language: BehaviorSubject<Language>) {}

    static convertSalesforceDataToArticle(sfArticle: SalesforceArticleDto): Article {
        const sfOrder = sfArticle.kav.Web_Page_Order__c || sfArticle.kav.Generic_Web_Page_Order__c;

        let idDesktop = '';
        let idMobile = '';
        let images: Image[] = [];

        if (sfArticle.kav.Id === 'ka03d0000001PLhAAM') {
            // debugger;
        }

        // find IDs for mobile and desktop if they exist
        if (sfArticle.contentVersionList) {
            sfArticle.contentVersionList.forEach((cv: SalesforceContentVersionListDto) => {
                if (cv.Mobile_Desktop_Image__c) {
                    if (cv.Mobile_Desktop_Image__c.toLowerCase() === 'desktop') {
                        idDesktop = cv.ContentDocumentId;
                    }

                    if (cv.Mobile_Desktop_Image__c.toLowerCase() === 'mobile') {
                        idMobile = cv.ContentDocumentId;
                    }
                }
            });
        }

        if (sfArticle.contentDistributionList) {
            images = sfArticle.contentDistributionList.map(item => {
                let desktopOrMobile = 'unknown';

                if (item.ContentDocumentId === idDesktop) {
                    desktopOrMobile = 'desktop';
                }

                if (item.ContentDocumentId === idMobile) {
                    desktopOrMobile = 'mobile';
                }

                return {
                    url: item.ContentDocumentId,
                    altText: '', // to be set in getArticles()
                    altTextPayload: {
                        [Language.En]: sfArticle.contentVersionList?.find(cv => cv.ContentDocumentId === item.ContentDocumentId).ALT_Text__c || '',
                        [Language.Es]: sfArticle.contentVersionList?.find(cv => cv.ContentDocumentId === item.ContentDocumentId).ALT_Text_Spanish__c || '',
                    },
                    desktopOrMobile,
                } as Image;
            });
        }

        return {
            id: sfArticle.kav.Id,
            title: sfArticle.kav.Title,
            bodyText: sfArticle.kav.Summary__c,
            linkText: sfArticle.kav.Link_Text__c,
            linkUrl: sfArticle.kav.Link_URL__c,
            tab: sfArticle.kav.Web_Page_Tab__c || sfArticle.kav.Generic_Web_Page__c,
            section: sfArticle.kav.Web_Page_Section__c || sfArticle.kav.Generic_Web_Page_Section__c,
            subSection: sfArticle.kav.Web_Page_Sub_Section__c || sfArticle.kav.Generic_Web_Page_Sub_Section__c,
            subSubsection: sfArticle.kav.Web_Page_Sub_Subsection__c,
            allPages: !!sfArticle.kav.Ignore_Tags__c,
            recordType: sfArticle.kav.RecordType.Name,
            displayTitle: sfArticle.kav.Display_Title__c,
            order: sfOrder ? Number(sfOrder) : undefined,
            images,
            tags: sfArticle.tagArticleAssociationList?.map(tagArticleAssociation => tagArticleAssociation.Tag_Name__c) || [],
            accounts: sfArticle.accArticleAssociationList?.map(a => a.Account_Resource__r) || [],
            sources:
                sfArticle.sourceArticleAssociationList?.map(({ Source_Title__c: title, Source_URL__c: url }) => ({
                    title,
                    url,
                })) || [],
            nullVersion: sfArticle.kav.NULL_Version__c,
            language: sfArticle.kav.Language || Language.En,
        } as Article;
    }

    fetch(): Observable<Article[]> {
        return this.httpGet().pipe(
            take(1),
            map(articles => articles.map(ArticleService.convertSalesforceDataToArticle)),
            tap(articles => {
                this.articles = articles.sort((a, b) => (a.order || 0) - (b.order || 0));
            }),
        );
    }

    getArticles(tab?: Page, section?: Section, subSection?: SubSection, order?: number, allPages?: boolean, nullVersion?: boolean, subSubsection?: SubSubsection): Article[] {
        const articles = this.articles.filter(
            article =>
                (tab === undefined || article.tab === tab) &&
                (section === undefined || article.section === section) &&
                (subSection === undefined || article.subSection === subSection) &&
                (order === undefined || article.order === order) &&
                (allPages === undefined || article.allPages === allPages) &&
                (nullVersion === undefined || article.nullVersion === nullVersion) &&
                (subSubsection === undefined || article.subSubsection === subSubsection) &&
                article.language === this.language.value,
        );

        // Because each KA image has both English and Spanish alt texts, we need to set the current one
        articles.forEach(article => {
            article.images.forEach((image: Image) => {
                let altText = '';
                if (image?.altTextPayload) {
                    if (image.altTextPayload[this.language.value]) {
                        altText = image.altTextPayload[this.language.value];
                    }
                }
                image.altText = altText;
                image.desktopOrMobile = 'desktop';
            });
        });

        return articles.map(article => ({ ...article }));
    }

    getOneDestruct({ tab, section, subSection, order, allPages, nullVersion, subSubsection }: ArticleParams) {
        return this.getOne(tab, section, subSection, order, allPages, nullVersion, subSubsection);
    }

    getOne(tab?: Page, section?: Section, subSection?: SubSection, order?: number, allPages?: boolean, nullVersion?: boolean, subSubsection?: SubSubsection): Article {
        // pull original article list, using filters, if provided
        const allArticles: Article[] = this.getArticles(tab, section, subSection, order, allPages, nullVersion, subSubsection);

        if (allArticles.length === 1) {
            // TODO: check to see if order was set, and if it was, that if it was removed, there would be an issue
            // if (order !== undefined) {
            //     const criteria: string[] = [`language: ${this.language.value}`];
            //
            //     ['tab', 'section', 'subsection', 'order', 'allPages', 'nullVersion', 'language'].forEach((key, index) => {
            //         if (arguments[index] !== undefined) {
            //             criteria.push(`${key}: ${arguments[index]}`);
            //         }
            //     });
            //     const errorMessage = `Knowledge Article uses ORDER in matching criteria:\n${criteria.join('\n')}\n`;
            //
            //     this.errorLogService.log('Order', errorMessage);
            // }

            // TODO: edge case here that we're not coding for.  Could only get one match by removing a single criteria
            // TODO: but could get multiple matches if we remove both

            // Good return
            return allArticles[0];
        } else {
            // Error cases
            const criteria: string[] = [`language: ${this.language.value}`];

            ['tab', 'section', 'subsection', 'order', 'allPages', 'nullVersion', 'subsubsection', 'language'].forEach((key, index) => {
                if (arguments[index] !== undefined) {
                    criteria.push(`${key}: ${arguments[index]}`);
                }
            });

            let errorMessage = '';
            if (allArticles.length === 0) {
                errorMessage = `No Knowledge Article matching criteria:\n${criteria.join('\n')}\n`;
            } else {
                errorMessage = `Multiple (${allArticles.length}) Knowledge Articles matching criteria:\n${criteria.join('\n')}`;
            }

            this.errorLogService.log('KA', errorMessage);

            return {
                title: `!!! DATA ERROR - (${allArticles.length}) MATCHES !!!`,
                bodyText: `!!! DATA ERROR: ${errorMessage.replace(/\n/g, ' | ')} !!!`,
            } as Article;
        }
    }

    private httpGet(): Observable<SalesforceArticleDto[]> {
        return this.http.get<SalesforceArticleDto[]>(PATHS.articleApi);
    }
}
