<div class="diagnostic-expert-wrapper container-fluid py-5">
    <div class="container-xxxl py-lg-4">
        <!-- Desktop -->
        <div class="desktop d-none d-xl-flex justify-content-between">
            <div class="row">
                <div class="col-7">
                    <app-bar-header [textHeader]="parentKA.title" theme="blue"></app-bar-header>

                    <div class="description mt-3 me-4 fs-18" [innerHTML]="parentKA.bodyText"></div>
                </div>
                <div class="col-5">
                    <img [src]="imgPath + parentKA.images[0]?.url + '-600.webp'" [alt]="parentKA.images[0]?.altText" />
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div class="mobile d-flex d-xl-none flex-column">
            <app-bar-header [textHeader]="parentKA.title" theme="blue"></app-bar-header>

            <clamper class="fs-6" [maxLines]="7" [text]="parentKA.bodyText"></clamper>

            <div class="mb-4 mx-auto">
                <img [src]="imgPath + parentKA.images[0]?.url + '-600.webp'" [alt]="parentKA.images[0]?.altText" />
            </div>
        </div>
    </div>
</div>
