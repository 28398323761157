<div class="contact-us-wrapper container-fluid bg-lightblue ps-0">
    <div class="container-xxxl mb-3 ps-4 ps-xl-0">
        <!-- desktop -->
        <div class="desktop ps-0 ms-0 pe-5 py-5 d-none d-lg-block fs-18 mt-5 mt-md-10 pt-5 pt-md-0 pb-5">
            <img [src]="image" [alt]="imageAlt" />
            <div class="row d-flex justify-content-between py-4">
                <div class="col-3 ps-0"></div>
                <div class="contact-card col-lg-8 col-xl-7 p-5 border border-blue bg-white shadow">
                    <div class="row">
                        <div class="col-6 pe-4">
                            <h1 class="text-blue border-start border-blue border-5 ps-4 mb-4">{{ titleKa.title }}</h1>
                            <p [innerHTML]="titleKa.bodyText"></p>
                        </div>
                        <div class="col-6 ps-4">
                            <h5 class="text-blue mb-4 fs-22 fw-bold">{{ phoneKa.title }}</h5>
                            <p class="text-link" [innerHTML]="phoneKa.bodyText"></p>

                            <h5 class="text-blue mb-4 fw-bold">{{ tollKa.title }}</h5>
                            <a [href]="'tel:' + tollKa.bodyText">
                                <h3 class="text-gard-green phone-text">{{ tollKa.bodyText }}</h3>
                            </a>

                            <div class="border-top border-2 border-gray my-5"></div>

                            <h5 class="text-blue mb-4 fs-22 fw-bold">{{ formKa.title }}</h5>
                            <p [innerHTML]="formKa.bodyText"></p>
                            <a link class="btn btn-green btn-arrow" [href]="buttonUrl" target="_blank" rel="noopener noreferrer"
                                >{{ buttonKa.title }} <i class="icon-gard-arrow-right ms-1"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- mobile -->
        <div class="d-block d-lg-none pt-5 mobile">
            <h3 class="text-blue border-start border-blue border-4 ps-4 mb-3 hl-1 fw-bold">{{ titleKa.title }}</h3>
            <p [innerHTML]="titleKa.bodyText"></p>
            <img class="w-100 mb-3" [src]="image" [alt]="imageAlt" />
            <div class="text-center">
                <h5 class="text-blue mb-2 fw-bold fs-22">{{ phoneKa.title }}</h5>

                <p class="mb-3 text-link" [innerHTML]="phoneKa.bodyText"></p>

                <h5 class="text-blue mb-2 pt-3 fs-18">{{ tollKa.title }}</h5>

                <a [href]="'tel:' + tollKa.bodyText">
                    <h3 class="phone text-gard-green mb-0 lh-1">{{ tollKa.bodyText }}</h3>
                </a>

                <div class="border border-1 border-light my-5"></div>

                <h5 class="text-blue mb-2 fs-22">{{ formKa.title }}</h5>
                <p [innerHTML]="formKa.bodyText"></p>

                <a link class="btn btn-arrow btn-green" [href]="buttonUrl" target="_blank" rel="noopener noreferrer"
                    >{{ buttonKa.title }} <i class="icon-gard-arrow-right ms-1"></i
                ></a>
            </div>
        </div>
    </div>
</div>
