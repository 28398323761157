<app-top-navigation></app-top-navigation>
<main-banner [disease]="disease"></main-banner>

<diagnostic-journey [id]="sections.stepsToADiagnosis.id"></diagnostic-journey>

<your-medical-team [id]="sections.medicalTeam.id"></your-medical-team>

<appointments-and-diagnostic-evaluations [id]="sections.workingWithYourDoctor.id" *ngIf="false"></appointments-and-diagnostic-evaluations>

<care-coordination [id]="sections.careCoordination.id" *ngIf="false"></care-coordination>

<rare-disease-expert [id]="sections.findingRareDiseaseExpert.id"></rare-disease-expert>

<!-- <advocate-for-yourself [id]="sections.takingNextSteps.id"></advocate-for-yourself> -->

<disease-section-contact-us page="Diagnosis"></disease-section-contact-us>

<app-disease-footer></app-disease-footer>
<app-bottom-sources-date-information></app-bottom-sources-date-information>
<app-footer></app-footer>
