<div class="caregiver-resources-wrapper container-fluid py-5 bg-greyish">
    <div class="container-xxxl">
        <div>
            <app-bar-header textHeader="Caregiver Resources" theme="blue"></app-bar-header>
        </div>
        <!-- Desktop -->
        <div class="desktop pb-3 mb-5 d-none d-lg-block">
            <div class="col col-lg-7 col-xl-6 fs-6 fs-md-18" [innerHTML]="parentKA.bodyText"></div>
        </div>
        <!-- Mobile -->
        <div class="mobile d-lg-none py-4">
            <clamper class="fs-6" [maxLines]="8" [text]="parentKA.bodyText"></clamper>
            <!-- <div class="col col-lg-7 col-xl-6 fs-6 fs-md-18" [innerHTML]="parentKA.bodyText"></div> -->
        </div>

        <div class="caregiver-cards d-flex flex-column align-items-center flex-xl-row align-items-xl-start justify-content-xl-between">
            <div class="mb-5 mb-xl-0">
                <app-research-participate-in-research-card
                    [cardTitle]="selfParentKA.title"
                    [cardBody]="selfParentKA.bodyText"
                    [additionalArticles]="selfResourceList"
                    [fixedHeight]="900"
                    [fullHeight]="true"
                    theme="blue"
                    iconUrl="/assets/medical-person.svg"
                ></app-research-participate-in-research-card>
            </div>
            <div>
                <app-research-participate-in-research-card
                    [cardTitle]="othersParentKA.title"
                    [cardBody]="othersParentKA.bodyText"
                    [additionalArticles]="otherResourceList"
                    [fixedHeight]="900"
                    [fullHeight]="true"
                    theme="teal"
                    iconUrl="/assets/medical-people.svg"
                ></app-research-participate-in-research-card>
            </div>
        </div>
    </div>
</div>
