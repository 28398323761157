import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Page as P, Section as S, SubSection as SS } from 'shared/location-enums';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Article } from 'shared/types';
import { PATHS } from 'shared/paths';

@Component({
    selector: 'co2-contact-center',
    templateUrl: './co2-contact-center.component.html',
    styleUrls: ['./co2-contact-center.component.scss'],
})
export class Co2ContactCenterComponent {
    phoneFax: Article;
    contactForm: Article;
    requestForm: Article;

    mainKa: Article;
    phoneKa: Article;
    tollFreeKa: Article;
    contactFormLink: Article;
    contactGard: Article;
    submitYourOrg: Article;

    mainTitle: string;
    mainBody: string;
    phoneTitle: string;
    phoneBody: string;
    tollTitle: string;
    tollBody: string;

    formTitle: string;
    formBody: string;
    buttonTitle: string;
    buttonUrl: SafeUrl;

    requestFormTitle: string;
    requestFormBody: string;
    requestButtonTitle: string;
    requestButtonUrl: SafeUrl;
    pageLanguage: string;

    requestFormIcon: string = 'assets/PO_Icon.svg';
    public imgPath = PATHS.articleImages;

    constructor(private articleService: ArticleService, private sanitizer: DomSanitizer) {
        this.phoneFax = articleService.getOne(P.contactUs, S.contactCenter, SS.phoneFax);
        this.contactForm = articleService.getOne(P.contactUs, S.contactCenter, SS.contactForm);
        this.requestForm = articleService.getOne(P.contactUs, S.contactCenter, SS.requestForm);

        this.mainKa = articleService.getOne(P.contactUs, S.contactCenter, undefined, 1);
        this.phoneKa = articleService.getOne(P.contactUs, S.contactCenter, undefined, 2);
        this.tollFreeKa = articleService.getOne(P.contactUs, S.contactCenter, undefined, 3);

        // TODO: replace order with subsection
        this.mainTitle = this.mainKa.title;
        this.mainBody = this.mainKa.bodyText;
        this.phoneTitle = this.phoneKa.title;
        this.phoneBody = this.phoneKa.bodyText;
        this.tollTitle = this.tollFreeKa.title;
        this.tollBody = this.tollFreeKa.bodyText;

        //  (this.pageLanguage ? this.pageLanguage : Language.En);
        // this.pageLanguage = activatedRoute.snapshot.data.language;

        // // any edits for MX?
        // if (this.pageLanguage === Language.Es) {
        //   // this.buttonUrl = this.buttonUrl.replace('language=es', 'language=es_MX');
        //   // debugger;
        // }

        //
        //
        //
        // Contact
        // contact button
        this.contactFormLink = articleService.getOne(P.contactUs, S.contactCenter, undefined, 4);
        this.formTitle = this.contactFormLink.title;
        this.formBody = this.contactFormLink.bodyText;

        const contactFormButtonKa = articleService.getOne(P.contactUs, S.contactCenter, undefined, 5);
        this.buttonTitle = contactFormButtonKa.title;

        this.contactGard = articleService.getOne(P.home, S.contactUs, undefined, 4);
        this.submitYourOrg = articleService.getOne(P.contactUs, S.contactCenter, undefined, 6);

        const contactFormButtonUrlKa = articleService.getOne(P.contactUs, S.contactCenter, SS.contactUsContactFormButton);
        this.buttonUrl = this.sanitizer.bypassSecurityTrustUrl(contactFormButtonUrlKa.bodyText);

        //
        //
        //
        // Request
        // Request button
        const requestFormButtonKa = articleService.getOne(P.contactUs, S.contactCenter, SS.requestForm);
        this.requestFormTitle = requestFormButtonKa.title;
        this.requestFormBody = requestFormButtonKa.bodyText;

        const requestFormButtonUrlKa = articleService.getOne(P.contactUs, S.contactCenter, SS.requestFormButton);
        this.requestButtonTitle = requestFormButtonUrlKa.title;
        this.requestButtonUrl = this.sanitizer.bypassSecurityTrustUrl(requestFormButtonUrlKa.bodyText);
    }
}
