<div class="container-fluid">
    <div class="container-xxxl">
        <!-- desktop -->
        <div class="d-none d-xl-block pb-3 pt-4">
            <div class="d-flex breadcrumb-container justify-content-center">
                <div class="pt-4" *ngFor="let breadcrumb of breadcrumbArticles; last as isLast; index as i">
                    <div class="pt-2 mb-4 d-flex align-items-center">
                        <div class="breadcrumb-btn">
                            <a class="button bg-white text-green fs-4" [ngClass]="{ active: i === activeIndex }" [routerLink]="i !== activeIndex ? breadcrumb.link : null">
                                {{ breadcrumb.title }}
                            </a>
                        </div>
                        <hr *ngIf="!isLast" class="separator mx-3" />
                    </div>
                    <p class="breadcrumb-txt text-center fs-16 mb-0" [innerHtml]="breadcrumb.bodyText"></p>
                </div>
            </div>
        </div>

        <!-- mobile -->
        <div class="d-block d-xl-none d-flex flex-column align-items-center pt-4">
            <a *ngIf="activeIndex !== 2" class="mobile-btn bg-white text-green fs-18 fw-500 mb-4" [routerLink]="breadcrumbArticles[activeIndex + 1]?.link">
                <span [innerText]="breadcrumbArticles[activeIndex + 1]?.title"></span><i class="icon-gard-arrow-right ms-2 fw-300"></i>
            </a>

            <p *ngIf="activeIndex !== 2" class="mobile-txt text-center fs-16 mb-4 px-3" [innerHtml]="breadcrumbArticles[activeIndex + 1]?.bodyText"></p>

            <a [routerLink]="breadcrumbArticles[activeIndex - 1]?.link" *ngIf="activeIndex !== 0" class="mobile-btn bg-white text-green fs-18 fw-500 mb-4">
                <i class="icon-gard-arrow-left me-2 fw-300"></i>
                <span>Back</span>
            </a>
        </div>
    </div>
</div>
