<ng-template #menuItems>
    <ul class="navbar-nav mx-auto mb-2 mb-lg-0 d-block d-lg-flex align-items-center">
        <li class="nav-item">
            <a class="nav-link fs-md-18" [routerLink]="paths.diseases" routerLinkActive="active" [innerText]="labels.diseases"></a>
        </li>
        <li class="nav-item">
            <a class="nav-link fs-md-18" [routerLink]="paths.about" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [innerText]="about"></a>
        </li>
        <li class="nav-item">
            <a class="nav-link fs-md-18" [routerLink]="paths.contactUs" routerLinkActive="active" [innerText]="labels.contact"></a>
        </li>
        <li class="nav-item icon">
            <a href="#" (click)="toggleSearch($event)" role="button" aria-label="Toggle search">
                <i [ngClass]="{ 'icon-gard-search': !isSearchOpen, 'icon-gard-close': isSearchOpen }" class="search-icon d-none d-lg-flex"></i>
            </a>
        </li>
    </ul>
</ng-template>

<nav class="container-fluid navbar hhs-nav">
    <ul class="container-xxxl d-flex align-items-center">
        <li class="nav-item text-nowrap list-inline-item fs-8 fs-md-6">
            <a
                class="nav-link top-logo-nih d-flex align-items-center"
                href="https://ncats.nih.gov/"
                title="National Center for Advancing Translational Sciences"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src="/assets/ncats-purple.svg" alt="ncats logo" />
                <span class="ms-1 text-bluegray">National Center for Advancing Translational Sciences</span>
            </a>
        </li>
    </ul>
</nav>

<div class="container-fluid position-relative bg-white top-nav-container no-margin">
    <div class="row">
        <div class="col-12 col-xxxl-10">
            <header class="container-xxl">
                <nav class="navbar navbar-expand-lg navbar-light my-3 me-3">
                    <a href="/" class="logo">
                        <div class="logos align-items-center d-xl-none">
                            <img src="/assets/GARD_Logo_Mobile.svg" alt="Genetic and Rare Diseases (GARD) information Center Home" />
                        </div>

                        <div class="logos align-items-center d-none d-xl-flex">
                            <img src="/assets/GARD_Logo.svg" alt="Genetic and Rare Diseases (GARD) information Center Home" />
                        </div>
                    </a>

                    <div class="d-flex d-lg-none align-items-center fs-5">
                        <div
                            role="button"
                            id="top-nav-open-menu"
                            aria-label="Toggle navigation"
                            [attr.aria-expanded]="isMenuOpen"
                            (click)="toggleMenu()"
                            aria-controls="navbarSupportedContent"
                        >
                            <i class="menu-icon me-3 icon-gard-menu" [hidden]="isMenuOpen"></i>
                            <i class="menu-icon me-3 icon-gard-close" [hidden]="!isMenuOpen"></i>
                        </div>

                        <a href="#" (click)="toggleSearch($event)" role="button" aria-label="Toggle search" [attr.aria-expanded]="isSearchOpen">
                            <i class="search-icon" [ngClass]="{ 'icon-gard-search': !isSearchOpen, 'icon-gard-close': isSearchOpen }"></i>
                        </a>
                    </div>

                    <div [ngClass]="{ 'menu-right': true, collapse: true, 'navbar-collapse': true, show: isMenuOpen }" class="d-none d-lg-flex" id="navbarSupportedContent">
                        <ng-container *ngTemplateOutlet="menuItems"></ng-container>
                    </div>
                </nav>
            </header>
        </div>

        <div class="col-0 col-xxxl-2">
            <div class="d-none d-xxxl-flex contact-center">
                <div class="container-fluid position-relative">
                    <div class="row">
                        <div class="col-12 right fs-18">
                            {{ contactCenter.title }}
                        </div>
                        <div class="col-12 left fs-20">
                            <a [href]="'tel:' + contactCenter.phone" role="button" aria-label="Call GARD" class="no-underline">
                                <img src="/assets/tel-icon.svg" alt="" />
                                {{ contactCenter.phone }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-items d-block d-lg-none position-absolute w-100 py-3" *ngIf="isMenuOpen">
                <ng-container *ngTemplateOutlet="menuItems"></ng-container>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid position-relative contact-center mobile">
    <div class="row">
        <div class="col-12 col-md-6 right fs-18">
            {{ contactCenter.title }}
        </div>
        <div class="col-12 col-md-6 left fs-20">
            <a [href]="'tel:' + contactCenter.phone" role="button" aria-label="Call GARD" class="no-underline">
                <img src="/assets/tel-icon.svg" alt="" />
                {{ contactCenter.phone }}
            </a>
        </div>
    </div>
</div>
