/* eslint-disable @typescript-eslint/naming-convention, no-shadow */

export enum Language {
  En = 'en_US',
  Es = 'es',
}

export const PATHS = {
  // Path parts
  diseasePageId: ':diseaseId',
  diseasePageName: ':name',
  diseases: 'diseases',

  // internal
  assets: '/assets/',
  diseasePageApi: '/assets/singles/',
  diseasesTrimmedApi: '/assets/diseases.trimmed.json',
  diseasesCategoriesApi: '/assets/diseases.categories.json',
  articleApi: '/assets/related/all-article-data.json',
  accountApi: '/assets/related/all-account-data.json',
  articleImages: '/assets/images/',
  accountImages: '/assets/images/',
  diseasePageEspanolSource: '/assets/spanish-source/',
  errorFile: '/assets/error.json',

  // Pages
  diseasePage: 'diseases/:diseaseId/:name',
  homePageEspanol: 'espanol',
  diseasePageEspanol: 'espanol/:diseaseId/:name',
  searchResultPage: 'search-result/:search',
  searchResultEmpty: 'search-result',

  notFound: 'not-found',

  // dual language
  [Language.En]: {
    about: 'about',
    contactUs: 'contact',
    privacyPolicy: 'privacy-policy',
    disclaimer: 'disclaimer',
    accessibility: 'accessibility',
    foia: 'FOIA',
    // oig: 'OIG',
    // vulnerability: 'vulnerability-disclosure-policy',
    diseases: 'diseases',
  },

  [Language.Es]: {
    base: 'espanol',
    about: 'espanol/acerca-de',
    contactUs: 'espanol/contacte-gard',
    privacyPolicy: 'espanol/privacy-policy',
    disclaimer: 'espanol/disclaimer',
    accessibility: 'espanol/accessibility',
    foia: 'espanol/FOIA',
    // oig: 'espanol/OIG',
    // vulnerability: 'espanol/vulnerability-disclosure-policy',
    diseases: 'espanol/enfermedades',
  },
};

export const PAGE_META = {
  home: {
    title: 'Genetic and Rare Diseases Information Center | GARD',
    description:
      'Discover how the Genetic and Rare Diseases Information Center Website and Contact Center can help patients and families who have a rare disease.',
  },
  diseaseSearch: {
    title: 'Diseases | GARD',
    description: 'Diseases',
  },
  // espanolDiseaseAbout: {
  //   title: '{diseaseName}',
  //   description: 'Encuentre síntomas y otra información sobre {diseaseName}',
  // },
  diseaseTabA: {
    title: '{diseaseName} | About the Disease | GARD',
    description: 'Find symptoms and other information about {diseaseName}.',
  },
  diseaseTabB: {
    title: '{diseaseName} | Getting a Diagnosis | GARD',
    description: 'Learn about diagnosis and specialist referrals for {diseaseName}.',
  },
  diseaseTabC: {
    title: '{diseaseName} | Resources and Support | GARD',
    description: 'Learn about research and find clinical studies for {diseaseName}.',
  },
  about: {
    title: 'About | GARD',
    description:
      'Learn about the Genetic and Rare Diseases Information Center, the GARD website and its policies, and how to access data used on the GARD website.',
  },
  privacyPolicy: {
    title: 'Privacy Policy | GARD',
    description: 'Privacy Policy',
  },
  vulnerability: {
    title: 'Vulnerability Disclosure Policy | GARD',
    description: 'Vulnerability Disclosure Policy',
  },
  foia: {
    title: 'FOIA | GARD',
    description: 'FOIA',
  },
  oig: {
    title: 'OIG | GARD',
    description: 'OIG',
  },
  accessibility: {
    title: 'Accessibility | GARD',
    description: 'Accessibility',
  },
  disclaimer: {
    title: 'Disclaimer | GARD',
    description: 'Disclaimer',
  },
  espanol: {
    title: 'Español | GARD',
    description: 'Español',
  },
  contactUs: {
    title: 'Contact Us | GARD',
    description:
      'Use the phone number or other contact options to ask a rare disease information specialist a question or provide feedback to the Genetic and Rare Diseases Information Center.',
  },
  // Spanish Meta
  [Language.Es]: {
    home: {
      title: 'Centro de Información sobre Enfermedades Raras y Genéticas | GARD',
      description: 'Política de Privacidad',
    },
    diseaseSearch: {
      title: 'Enfermedades | GARD',
      description: 'Enfermedades',
    },
    diseasePage: {
      title: '{diseaseName} | Sobre la enfermedad | GARD',
      description: 'Encuentre síntomas y otra información sobre {diseaseName}',
    },
    about: {
      title: 'Sobre | GARD',
      description:
        'Learn about the Genetic and Rare Diseases Information Center, the GARD website and its policies, and how to access data used on the GARD website.',
    },
    privacyPolicy: {
      title: 'Política de privacidad | GARD',
      description: 'Política de Privacidad',
    },
    disclaimer: {
      title: 'Aviso Legal | GARD',
      description: 'Descargo de responsabilidad',
    },
    accessibility: {
      title: 'Accesibilidad | GARD',
      description: 'Accesibilidad',
    },
    foia: {
      title: 'FOIA | GARD',
      description: 'FOIA',
    },
    vulnerability: {
      title: 'Vulnerability Disclosure Policy | GARD',
      description: 'Vulnerability Disclosure Policy',
    },
    oig: {
      title: 'OIG | GARD',
      description: 'OIG',
    },
    espanol: {
      title: 'Español | GARD',
      description: 'Español',
    },
    contactUs: {
      title: 'Contáctenos | GARD',
      description:
        'Use the phone number or other contact options to ask a rare disease information specialist a question or provide feedback to the Genetic and Rare Diseases Information Center.',
    },
  },
};

export const FOOTER_LINKS = {
  oig: 'https://oig.hhs.gov/',
};

export const ProdPsoFormUrl = {
  url: 'https://contact.rarediseases.info.nih.gov/Gard/s/patient-organization?language=',
};
