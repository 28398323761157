/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any */
import { Page, Section, SubSection, SubSubsection } from './location-enums';
import {
  AgeAtOnset,
  DiagnosisItem,
  Feature,
  DiseaseDrug,
  Organization,
  Evidence,
  ContentDistribution,
  Gene,
  Epidemiology,
  Language,
} from './salesforce-types';

export interface ExternalIdentifier {
  value: string;
  url: string;
  source: string;
}

export interface Disease extends DiseaseTrimmed {
  synonymsList?: string;
  clinicalDescription?: string;
  diseaseCategoriesWithDescriptions?: Categories[];
  ageAtOnset?: AgeAtOnset[];
  ageAtDeath?: AgeAtOnset[];
  symptoms?: Feature[];
  labs?: Feature[];
  features?: Feature[];
  drugs?: DiseaseDrug[];
  caseEstimateUsa?: string;
  diagnosis?: DiagnosisItem[];
  organizationSupported?: Organization[];
  externalIdentifiers?: ExternalIdentifier[];
  evidence?: Evidence[];
  genes?: Gene[];
  epidemiology?: Epidemiology[];
  ageAtOnsetSnippet?: string;
  inheritance?: string[];
  tagsDiseaseCategory?: string[];
  tagsSpecialist?: string[];
  tagsCause?: string[];
}

export interface DiseaseTrimmed {
  id: number;
  name: string;
  encodedName: string;
  spanishId?: number;
  spanishName?: string;
  synonyms?: string[];
  diseaseCategories?: string[];
}

export interface AgeAtOnsetAge {
  icon: string;
  age: string;
  active: boolean;
  title: string;
  year: string;
  connected: boolean;
}

export interface Source {
  title: string;
  url: string;
}

export type AltText = {
  [K in Language]: string;
};

export interface Image {
  url: string;
  altText?: string;
  altTextPayload?: AltText;
  desktopOrMobile?: 'desktop' | 'mobile' | 'unknown';
}

export interface Article {
  id: string;
  tab?: Page;
  section?: Section;
  subSection?: SubSection;
  subSubsection?: SubSubsection;
  order?: number;
  title: string;
  bodyText: string;
  images?: Image[];
  tags?: string[];
  accounts?: GardAccount[];
  sources?: Source[];
  allPages?: boolean;
  displayTitle?: boolean;
  recordType?: string;
  nullVersion: boolean;
  language?: Language;
  linkText?: string;
  linkUrl?: string;
}

export interface GardAccount {
  Id: string;
  Name: string;
  Website: string;
}

export interface Categories {
  name: string;
  nameCurated: string;
  textSnippet: string;
}

export interface CategoriesWithParent extends Categories {
  ParentTag?: string;
}

export type NonFunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => void ? never : K;
}[keyof T];

export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

export interface Dictionary<T> {
  [Key: string]: T;
}

export interface Account {
  id: string;
  name: string;
  type: string;
  website: string;
  services: string[];
  country: string;
  providedServices: string[];
  additionalPostedInfo: string;
  languages: string[];
  tab: Page;
  section: Section;
  subSection: SubSection;
  order: number;
  allPages: boolean;
  contentDistributionList: ContentDistribution[];
  expertDirectoryUrl: string;
  patientRegistryUrl: string;
  recordType: string;
  diseaseCategory: string[];
  epidemiology: string[];
  tags?: string[];
}

export interface SpanishPageTransform {
  english: number;
  spanish: number;
  spanishName: string;
  englishName: string;
}

export class MessageKey {
  private pKey: string;

  constructor(key: string) {
    this.pKey = key;
  }

  get key(): string {
    return this.pKey;
  }
}

export interface Slide {
  title: string;
  description: string;
  link?: string | any[];
  externalLink?: string;
  imageAlt?: string;
  imageAltPayload?: AltText;
  image?: string;
}
