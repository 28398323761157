<app-top-navigation></app-top-navigation>
<main-banner [disease]="disease"></main-banner>

<!-- C1 -->
<living-with-intro [id]="sections.livingIntro.id"></living-with-intro>

<!-- C3 -->
<c4-suggested-resources [id]="sections.patientResources.id"></c4-suggested-resources>

<!-- C4 -->
<app-caregiver-resources [id]="sections.caregiverResources.id"></app-caregiver-resources>

<!-- C5 -->
<app-living-with-informed-decisions [id]="sections.decisions.id"></app-living-with-informed-decisions>

<disease-section-contact-us page="LivingWith"></disease-section-contact-us>

<!-- <app-prev-next-button backLink="../diagnosis" forwardLink="../research" forwardDescription="Research"></app-prev-next-button> -->
<app-disease-footer></app-disease-footer>
<app-bottom-sources-date-information></app-bottom-sources-date-information>
<app-footer></app-footer>
